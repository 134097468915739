.btn-primary {
    background-color: #0C79BF;
}

.tick {
    background-color: #1dab45;
    color: #ffffff;
    margin: 5px;
    line-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 12px;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50px;
}

.tick-form {
    display: inline-block;
    background-color: #1dab45;
    color: #ffffff;
    line-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
}
.cross-form {
    display: inline-block;
    background-color: #dc3545;
    color: #ffffff;
    line-height: 20px;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    text-align: center;
    vertical-align: text-bottom;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
}

.select-database {
    border-width: 1px;
    border-style: solid;
    border-color: #383838;
    padding: 5px;
}